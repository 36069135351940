<template>
  <location-list />
</template>

<script>
import { onMounted } from '@vue/composition-api'
import LocationList from './LocationList.vue'

export default {
  components: {
    LocationList,
  },
  setup() {
    onMounted(() => {
      if (window.location.href.indexOf('/locations/list-display') > -1) {
        setTimeout(() => {
          document.getElementById('add-display-btn').click()
        }, 1000)
      }
    })

    return {
    }
  },
}
</script>
